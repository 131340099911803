<template>
  <h1>Logging Out....</h1>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  mounted() {
    this.logOut()
  },
  methods: {
    ...mapActions(['LogOutShop']),
    async logOut() {
      let resposne = await this.LogOutShop()
      if (resposne.success != true) {
        this.$swal.fire({
          icon: 'error',
          title: 'Oops...',
          text: 'Something went wrong!',
        })
      } else {
        window.location = '/dashboard'
      }
    },
  },
}
</script>